import * as React from 'react';
import Title from '../General/Title';
import ScanEvent from '../../models/ScanEvent';
import AiFiEvent from '../../models/AiFiEvent';
import EventItem from '../General/EventItem';
import { Paper, TablePagination, Typography } from '@mui/material';
import API from '../../datalayer/API';
import TitleLive from '../General/TitleLive';
import eventEmitter from '../../datalayer/EventEmitter';
import { EventType } from '../../models/EventType';
import CheckinEvent from '../../models/CheckinEvent';
import { AiFiCheckoutZoneEnteredFlag } from '../../models/AiFiEventPayload';
import TapAppInEvent from '../../models/TapAppInEvent';
import { useEffect } from 'react';
import ScrollEventEmitter from '../../datalayer/ScrollEventEmitter';
import AgeFlaggingStatistics from './AgeFlaggingStatistics';

export default function EventList() {
	const [loading, setLoading] = React.useState(false);
	const [hasMore, setHasMore] = React.useState(true);

	const [events, setEvents] = React.useState<Array<AiFiEvent | ScanEvent | CheckinEvent | TapAppInEvent>>([]);
	const [flaggedCheckoutZoneEnteredEvents, setFlaggedCheckoutZoneEnteredEvents] = React.useState(0);
	const [totalCheckoutZoneEnteredEvents, setTotalCheckoutZoneEnteredEvents] = React.useState(0);
	const [rate, setRate] = React.useState(0);

	useEffect(() => {
		setRate(totalCheckoutZoneEnteredEvents === 0 ? 0 : Math.round((flaggedCheckoutZoneEnteredEvents / totalCheckoutZoneEnteredEvents) * 100));
	}, [flaggedCheckoutZoneEnteredEvents, totalCheckoutZoneEnteredEvents]);

	useEffect(() => {
		const handleNewEvent = (event: AiFiEvent | ScanEvent | CheckinEvent | TapAppInEvent) => {
			setEvents([event, ...events]);

			if (event.type === EventType.CHECKOUT_ZONE_ENTERED) {
				setTotalCheckoutZoneEnteredEvents(totalCheckoutZoneEnteredEvents + 1);
				if ((event as AiFiEvent).body.flags.includes(AiFiCheckoutZoneEnteredFlag.POTENTIALLY_INTERACTED_WITH_ALCOHOL)) {
					setFlaggedCheckoutZoneEnteredEvents(flaggedCheckoutZoneEnteredEvents + 1);
				}
			}
		};

		eventEmitter.addListener('storeEvent', handleNewEvent);

		return () => {
			eventEmitter.removeListener('storeEvent', handleNewEvent);
		};
	}, [events, flaggedCheckoutZoneEnteredEvents, totalCheckoutZoneEnteredEvents]);

	const fetchEvents = async ({ limit, lastId, reset }: { limit: number; lastId?: string; reset?: boolean }) => {
		setLoading(true);
		API.getEvents({ amount: limit, lastId })
			.then((data) => {
				setHasMore(data.length === limit);
				if (reset) {
					setEvents(data);
				} else {
					setEvents([...events, ...data]);
				}
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	};

	const reload = () => {
		fetchEvents({ limit: 20, reset: true });
	};

	useEffect(() => {
		reload();
		const startDate = new Date(Date.now() - 60 * 60 * 1000);
		const endDate = new Date();
		API.getAgeRestrictedFlagRate({ startDate, endDate }).then((data) => {
			setFlaggedCheckoutZoneEnteredEvents(data.flaggedAmount);
			setTotalCheckoutZoneEnteredEvents(data.totalAmount);
		});
	}, []);

	useEffect(() => {
		const bottomOfPageReached = () => {
			if (hasMore && !loading) {
				const lastId = events.at(-1)?._id;
				fetchEvents({ limit: 20, lastId });
			}
		};
		ScrollEventEmitter.on('bottom', bottomOfPageReached);
		return () => {
			ScrollEventEmitter.off('bottom', bottomOfPageReached);
		};
	}, [events, hasMore, loading]);

	return (
		<Paper
			sx={{
				p: 2,
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<TitleLive sseRelevant={true} refresh={reload} lastRefresh={new Date()} centerComponent={<AgeFlaggingStatistics />}>
				Events
			</TitleLive>

			{events.map((event, index) => {
				return <EventItem event={event} key={event.timestamp + event.type} displayButton={true} displayFullDate={true} />;
			})}
		</Paper>
	);
}
