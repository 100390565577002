import API from '../../datalayer/API';
import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

export default function AgeFlaggingStatistics() {
	const [loading, setLoading] = useState(false);
	const [accuracyVisible, setAccuracyVisible] = useState(false);
	const [rate, setRate] = useState(0);
	const [falsePositiveRate, setFalsePositiveRate] = useState(0);
	const [falseNegativeRate, setFalseNegativeRate] = useState(0);
	const [falseNegativeIds, setFalseNegativeIds] = useState<string[]>([]);

	useEffect(() => {
		const startDate = new Date(Date.now() - 60 * 60 * 1000);
		const endDate = new Date();
		API.getAgeRestrictedFlagRate({ startDate, endDate }).then((data) => {
			setRate(data.totalAmount === 0 ? 0 : Math.round((data.flaggedAmount / data.totalAmount) * 100));
		});
	}, []);

	useEffect(() => {
		if (!accuracyVisible) {
			return;
		}
		const startDate = new Date();
		startDate.setHours(4, 0, 0, 0);
		const endDate = new Date();
		setLoading(true);
		API.getAgeRestrictionAccuracy({ startDate, endDate }).then((data) => {
			setFalsePositiveRate(data.ageFlagFalsePositiveRate * 100);
			setFalseNegativeRate(data.ageFlagFalseNegativeRate * 100);
			setFalseNegativeIds(data.ageFlagFalseNegativesSessionIds);
			setLoading(false);
		});
	}, [accuracyVisible]);

	const viewAccuracy = () => {
		setAccuracyVisible(true);
	};

	const copyFalseNegativeIds = () => {
		navigator.clipboard.writeText(falseNegativeIds.join(', '));
	};

	if (loading) {
		<Typography variant='body2' sx={{ mt: 'auto', mb: 'auto', ml: 'auto' }}>
			Loading...
		</Typography>;
	}

	if (accuracyVisible) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto' }}>
				<Typography variant='body2' sx={{ mt: 'auto', mb: 'auto', mr: 2 }}>
					False Positive Rate: {falsePositiveRate}% / False Negative Rate: {falseNegativeRate}%
				</Typography>

				<Button variant='text' onClick={copyFalseNegativeIds} sx={{ ml: 'auto' }}>
					Copy False Negative IDs
				</Button>
			</Box>
		);
	}

	return (
		<Button variant='text' onClick={viewAccuracy} sx={{ ml: 'auto' }}>
			{rate}% flagged for restricted items
		</Button>
	);
}
