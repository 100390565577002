import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import JourneyPage from './pages/JourneyPage';
import MainPage from './pages/MainPage';
import DashboardFrame from './DashboardFrame';
import JourneyDetailPage from './pages/JourneyDetailPage';
import SignInPage from './pages/SignInPage';
import { AuthGate } from './components/General/AuthGate';
import { store, persistor } from './datalayer/store';
import { PersistGate } from 'redux-persist/integration/react';
import OrderPage from './pages/OrderPage';
import CheckinPage from './pages/CheckinPage';
import AccuracyPage from './pages/AccuracyPage';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AccuracyDetailPage from './pages/AccuracyDetailPage';
import ReceiptFinderPage from './pages/ReceiptFinderPage';
import OverridePage from './pages/OverridePage';
import IssueReportPage from './pages/IssueReportPage';
import SystemHealthPage from './pages/SystemHealthPage';
import CustomerPage from './pages/CustomerPage';
import ProductsPage from './pages/ProductPage';
import ProductDetailsPage from './pages/ProductDetailsPage';
import ProductGondolaPage from './pages/ProductGondolaPage';
import ErrorPage from './pages/ErrorPage';
import WriteOffPage from './pages/WriteOffPage';

const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AuthGate>
				<DashboardFrame />
			</AuthGate>
		),
		errorElement: <div>Something went wrong.</div>,
		children: [
			{
				path: 'journeys',
				element: <JourneyPage />,
			},
			{
				path: 'customer',
				element: <CustomerPage />,
			},
			{
				path: 'orders',
				element: <OrderPage />,
			},
			{
				path: 'journey',
				element: <JourneyDetailPage />,
			},
			{
				path: 'checkins',
				element: <CheckinPage />,
			},
			{
				path: 'receipts',
				element: <ReceiptFinderPage />,
			},
			{
				path: 'accuracy',
				element: <AccuracyPage />,
			},
			{
				path: 'accuracy/:id',
				element: <AccuracyDetailPage />,
			},
			{
				path: 'overrides',
				element: <OverridePage />,
			},
			{
				path: 'issue',
				element: <IssueReportPage />,
			},
			{
				path: 'system-health',
				element: <SystemHealthPage />,
			},
			{
				path: 'products',
				element: <ProductsPage />,
			},
			{
				path: 'product',
				element: <ProductDetailsPage />,
			},
			{
				path: 'gondola',
				element: <ProductGondolaPage />,
			},
			{
				path: 'writeoff',
				element: <WriteOffPage />,
			},
			{
				path: 'error',
				element: <ErrorPage />,
			},
			{
				path: '',
				element: <MainPage />,
			},
		],
	},
	{
		path: '/login',
		element: <SignInPage />,
	},
	{
		path: '/health',
		element: <div>OK</div>,
	},
]);

root.render(
	<React.StrictMode>
		<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='en-gb'>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<RouterProvider router={router} />
				</PersistGate>
			</Provider>
		</LocalizationProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
