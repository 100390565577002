import { useEffect } from 'react';
import { EventSseContent, GateScreensSseContent, SseContent, SseContentType } from '../models/SSE/SseContent';
import { SseMessage, SseMessageType } from '../models/SSE/SseMessage';
import { RootState } from './MainReducer';
import { useAppDispatch, useAppSelector } from './hooks';
import API from './API';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { setSseAllowed, setSseConnected, updateGateScreenStates } from './Actions';
import eventEmitter from './EventEmitter';

export default function useEventStream() {
	const dispatch = useAppDispatch();

	const url = `${API.config.aisBaseUrl}/staffApp/events?eventTypes=GATE_SCREEN,STORE_MODE,EVENT`;

	const isSignedIn = useAppSelector((state: RootState) => state.status.isSignedIn);

	const lastReconnectTimestamps: Array<number> = [];

	let latestPing: Date = new Date();

	let pingCheckerInterval: NodeJS.Timer | undefined;

	let eventSource: EventSourcePolyfill | undefined;

	useEffect(() => {
		if (!isSignedIn) return;

		initialiseConnection();

		return disconnect;
	}, [isSignedIn]);

	const initialiseConnection = () => {
		stopPingCheckerInterval();
		disconnect();
		const now = Date.now();
		lastReconnectTimestamps.push(now);
		if (lastReconnectTimestamps.length > 3) {
			lastReconnectTimestamps.shift();
			if (lastReconnectTimestamps[0] - now < 60000) {
				console.log('SSE connection failed 3 times in 60 seconds, disabling SSE');
				dispatch(setSseAllowed(false));
				return;
			}
		}
		connect();
		addListeners();
		startPingCheckerInterval();
	};

	const disconnect = () => {
		stopPingCheckerInterval();
		dispatch(setSseConnected(false));
		if (eventSource == null) return;
		console.log('closing SSE connection');
		if (eventSource == null) {
			return;
		}
		try {
			eventSource.removeEventListener('message', messageListener);
			eventSource.removeEventListener('open', openListener);
			eventSource.removeEventListener('error', errorListener);
		} catch (e) {}
		try {
			eventSource.close();
		} catch (e) {}
		eventSource = undefined;
	};

	const connect = () => {
		console.log('connecting to SSE stream...');
		latestPing = new Date();
		eventSource = new EventSourcePolyfill(url, {
			withCredentials: !API.config.useLocalStorageAuth,
			headers: { ...API.getLocalStorageAuthHeader() },
		});
	};

	const openListener = (event: any) => {
		console.log('SSE connection established');
		latestPing = new Date();
	};

	const messageListener = (event: any) => {
		if (event.type !== 'message') return;
		if (event.data == null) return;
		dispatch(setSseAllowed(true));
		dispatch(setSseConnected(true));
		handleSseMessage(JSON.parse(event.data) as SseMessage);
	};

	const errorListener = (event: any) => {
		console.log('SSE connection error');
		console.log(event);
		console.log('reconnecting');
		initialiseConnection();
	};

	const addListeners = () => {
		if (eventSource == null) return;

		eventSource.addEventListener('open', openListener);

		eventSource.addEventListener('message', messageListener);

		eventSource.addEventListener('error', errorListener);
	};

	const handleSseMessage = (message: SseMessage) => {
		switch (message.type) {
			case SseMessageType.PING:
				return handlePing();
			case SseMessageType.RELOAD:
				return handleReload();
			case SseMessageType.CONTENT:
				return handleContent(message.content as SseContent);
			default:
				console.error(`could not handle SSE message with type ${message.type}`);
		}
	};

	const handlePing = () => {
		latestPing = new Date();
	};

	const startPingCheckerInterval = () => {
		pingCheckerInterval = setInterval(() => {
			if (latestPing.getTime() < Date.now() - 10000) {
				console.log(`reconnecting due to latest ping being ${(Date.now() - latestPing.getTime()) / 1000}s ago`);
				initialiseConnection();
			}
		}, 10000);
	};

	const stopPingCheckerInterval = () => {
		if (pingCheckerInterval == null) return;
		clearInterval(pingCheckerInterval);
		pingCheckerInterval = undefined;
	};

	const handleReload = () => {
		console.log('reconnecting');
		initialiseConnection();
	};

	const handleContent = (content: SseContent) => {
		latestPing = new Date();
		switch (content.type) {
			case SseContentType.GATE_SCREEN:
				return handleGateScreenContent(content as GateScreensSseContent);
			case SseContentType.EVENT:
				return handleEventContent(content as EventSseContent);
			default:
				console.error(`could not handle SSE content with type ${content.type}`);
		}
	};

	const handleGateScreenContent = (content: GateScreensSseContent) => {
		dispatch(updateGateScreenStates(content.gateScreenContent));
	};

	const handleEventContent = (content: EventSseContent) => {
		eventEmitter.emit('storeEvent', content.event);
	};
}
