import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { QrCodeScanner, ErrorOutline, DoorSlidingOutlined, CenterFocusWeak, CropFree } from '@mui/icons-material';
import { Color } from '../../assets/Color';
import ScanEvent from '../../models/ScanEvent';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';

const ScanEventItem: React.FC<{ event: ScanEvent; displayButton: boolean; displayFullDate: boolean }> = ({ event, displayButton, displayFullDate }) => {
	let link = '/journey';
	if (event.body.gateId.startsWith('entry')) {
		link += `?verificationCode=${(event as ScanEvent).body.code}`;
	} else if (event.body.gateId.startsWith('exit')) {
		link += `?exitCode=${(event as ScanEvent).body.code}`;
	}

	const gateIdParts = event.body.gateId.replace(/([A-Z])/g, ' $1').replace(/([0-9])/g, ' $1');
	const gateIdString = gateIdParts.charAt(0).toUpperCase() + gateIdParts.slice(1) + ` (${event.body.scannerPosition} scanner)`;

	return (
		<Paper elevation={3} sx={{ display: 'flex', mt: 2, p: 2 }}>
			{event.body.success ? (
				<CenterFocusWeak sx={{ fontSize: 30, color: Color.BLUE, mr: 2, mt: 'auto', mb: 'auto', flexShrink: 1 }} color='primary' />
			) : (
				<Box sx={{ display: 'grid', mt: 'auto', mb: 'auto', flexShrink: 1 }}>
					<CropFree sx={{ fontSize: 30, color: Color.RED, mr: 2, gridColumn: 1, gridRow: 1 }} color='primary' />
					<ErrorOutline sx={{ fontSize: 15, color: Color.RED, mr: 2, gridColumn: 1, gridRow: 1, marginLeft: '0.5rem', marginTop: '0.5rem' }} />
				</Box>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{event.body.success ? 'SCAN' : 'SCAN FAILED'}</Typography>
				<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
					{displayFullDate ? new Date(event.timestamp).toLocaleString() : new Date(event.timestamp).toLocaleTimeString()}
				</Typography>
			</Box>
			{event.body.success === false && (
				<Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 999999 }}>
					<ErrorOutline sx={{ fontSize: 13, color: Color.RED, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13, fontWeight: 'bold' }}>{event.body.errorMessage}</Typography>
				</Box>
			)}
			<Box sx={{ display: 'flex', flexDirection: 'column', ml: 'auto', mr: 1, mt: 'auto', mb: 'auto', flexShrink: 1 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<DoorSlidingOutlined sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
					<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>{gateIdString}</Typography>
				</Box>
				{!event.body.success && (
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<QrCodeScanner sx={{ fontSize: 13, color: Color.BLUE, mr: 1, mt: 'auto', mb: 'auto' }} />
						<Typography sx={{ color: Color.GRAY, fontSize: 13 }}>
							{event.body.code && event.body.code.length > 30 ? event.body.code.slice(0, 30) + '...' : event.body.code}
						</Typography>
					</Box>
				)}
			</Box>

			{displayButton && event.body.code.startsWith('c') && event.body.success && (
				<Button
					size='small'
					variant='outlined'
					sx={{ mt: 'auto', mb: 'auto', ml: 'auto', color: Color.GRAY, borderColor: Color.GRAY, flexShrink: 1 }}
					target='_blank'
					href={link}
				>
					View Journey
				</Button>
			)}
		</Paper>
	);
};

export default ScanEventItem;
