import AiFiEvent from '../AiFiEvent';
import CheckinEvent from '../CheckinEvent';
import GateScreenState from '../GateScreenState';
import ScanEvent from '../ScanEvent';
import TapAppInEvent from '../TapAppInEvent';

export interface SseContent {
	type: SseContentType;
}

export interface GateScreensSseContent extends SseContent {
	type: SseContentType.GATE_SCREEN;
	gateScreenContent: Array<GateScreenState>;
}

export interface EventSseContent extends SseContent {
	type: SseContentType.EVENT;
	event: ScanEvent | AiFiEvent | CheckinEvent | TapAppInEvent;
}

export enum SseContentType {
	GATE_SCREEN = 'GATE_SCREEN',
	EVENT = 'EVENT',
}
