export const DELETE_STORE = 'DELETE_STORE';

export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const SET_TOKEN_EXPIRY_DATE = 'SET_TOKEN_EXPIRY_DATE';
export const SET_ROLES = 'SET_ROLES';
export const SET_SSE_CONNECTED = 'SET_SSE_CONNECTED';
export const SET_SSE_ALLOWED = 'SET_SSE_ALLOWED';

export const SET_GATE_SCANNERS = 'SET_GATE_SCANNERS';
export const UPDATE_GATE_SCREEN_STATES = 'UPDATE_GATE_SCREEN_STATES';
export const SET_GATE_SCREEN_STATES = 'SET_GATE_SCREEN_STATES';

export const SET_PLANOGRAM_PRODUCT = 'SET_PLANORGAM_PRODUCT';
export const SET_PRODUCT = 'SET_PRODUCT';
