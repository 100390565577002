import React, { useEffect } from 'react';
import API from '../datalayer/API';
import {
	Alert,
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import Title from '../components/General/Title';
import PricingOverride from '../models/PricingOverride';
import { Add } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';

const OverridePage = () => {
	const [overrides, setOverrides] = React.useState<Array<PricingOverride>>([]);
	const [open, setOpen] = React.useState(false);
	const [snack, setSnack] = React.useState<{ message: string; severity: 'success' | 'error' | 'info' | 'warning' } | null>(null);

	const [newOriginalBarcode, setNewOriginalBarcode] = React.useState<string | undefined>(undefined);
	const [newUpdatedBarcode, setNewUpdatedBarcode] = React.useState<string | undefined>(undefined);
	const [date, setDate] = React.useState<DateTime | undefined>(undefined);

	const deleteOverride = async (originalBarcode: string) => {
		try {
			setSnack({ message: 'Deleting override...', severity: 'info' });
			await API.deleteOverride(originalBarcode);
			setSnack({ message: 'Override deleted', severity: 'success' });
		} catch (e) {
			console.error(e);
			setSnack({ message: 'Error deleting override', severity: 'error' });
		}
		try {
			await getOverrides();
		} catch (e) {
			console.error(e);
		}
	};

	const editOverride = (originalBarcode: string, updatedBarcode: string, validUntil: string) => {
		setNewOriginalBarcode(originalBarcode);
		setNewUpdatedBarcode(updatedBarcode);
		setDate(DateTime.fromISO(validUntil));
		setOpen(true);
	};

	const addOverride = () => {
		setOpen(true);
	};

	const saveNewOverride = async () => {
		if (!newOriginalBarcode || !newUpdatedBarcode) return;

		try {
			setSnack({ message: 'Adding override...', severity: 'info' });
			await API.createOverride({
				originalBarcode: newOriginalBarcode,
				updatedBarcode: newUpdatedBarcode,
				validUntil: date?.toJSDate().toISOString() || new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString(),
			});
			setOpen(false);
			setNewOriginalBarcode(undefined);
			setNewUpdatedBarcode(undefined);
			setDate(undefined);
			setSnack({ message: 'Override saved', severity: 'success' });
		} catch (error) {
			console.error(error);
			setSnack({ message: 'Error adding override', severity: 'error' });
		} finally {
			setTimeout(() => {
				setSnack(null);
			}, 2000);
		}
		try {
			await getOverrides();
		} catch (e) {
			console.error(e);
		}
	};

	const getOverrides = async () => {
		try {
			const res = await API.getOverrides();
			setOverrides(res);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getOverrides();
	}, []);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
			{snack ? (
				<Snackbar open={true} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert severity={snack.severity} sx={{ width: '100%' }}>
						{snack.message}
					</Alert>
				</Snackbar>
			) : null}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Add Override</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin='dense'
						label='Original Barcode'
						type='text'
						fullWidth
						variant='outlined'
						sx={{ mb: 2 }}
						value={newOriginalBarcode}
						onChange={(newValue) => {
							setNewOriginalBarcode(newValue.target.value);
						}}
					/>
					<TextField
						margin='dense'
						label='Updated Barcode'
						type='text'
						fullWidth
						sx={{ mb: 2 }}
						variant='outlined'
						value={newUpdatedBarcode}
						onChange={(newValue) => {
							setNewUpdatedBarcode(newValue.target.value);
						}}
					/>
					<DatePicker
						label='Valid Until'
						sx={{ width: '100%' }}
						value={date}
						onChange={(newValue) => {
							//@ts-ignore
							setDate(newValue);
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={saveNewOverride}>Save</Button>
				</DialogActions>
			</Dialog>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mb: 4,
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: 2, pl: 2, pr: 2, pb: 2 }}>
					<Title>Product Overrides</Title>
					<Button variant='contained' onClick={addOverride} startIcon={<Add />}>
						Create Override
					</Button>
				</Box>
			</Paper>

			<Paper sx={{ width: '100%', mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow>
								<TableCell>Original Barcode</TableCell>
								<TableCell>Updated Barcode</TableCell>
								<TableCell align='right'>Valid Until</TableCell>
								<TableCell align='right'>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{overrides.map((override: any) => (
								<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={override.originalBarcode}>
									<TableCell component='th' scope='row'>
										{override.originalBarcode}
									</TableCell>
									<TableCell>
										{override.updatedBarcode ? override.updatedBarcode : <span style={{ fontWeight: 'bold' }}>please specify</span>}
									</TableCell>
									<TableCell align='right'>{new Date(override.validUntil).toLocaleDateString()}</TableCell>
									<TableCell align='right'>
										<Button
											variant='outlined'
											sx={{ mr: 2 }}
											onClick={() => editOverride(override.originalBarcode, override.updatedBarcode, override.validUntil)}
										>
											Edit
										</Button>
										<Button variant='outlined' onClick={() => deleteOverride(override.originalBarcode)}>
											Delete
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Container>
	);
};

export default OverridePage;
