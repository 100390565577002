import PricingReceipt from "./PricingReceipt";

export default interface DashboardOrder {
	_id: string;
	journeyCustomerId: number;
	type: OrderType;
	cart: Cart | null;
	refundRequest: RefundRequest | null;
	orderCustomerId: number;
	orderReceiptId: string;
	parentReceiptId: string;
	sprykerOrderReference: string;

	pricingReceipt: PricingReceipt | null;
	paymentState: PaymentState;
	paymentStateMillis: any;
	bopState: BopState;
	bopStateMillis: any;
}

export enum OrderType {
	ORDER = 'ORDER',
	UPDATED_ORDER = 'UPDATED_ORDER',
	REFUND = 'REFUND',
}

export enum PaymentState {
	CANCELLED = 'CANCELLED',
	CUSTOMER_ID_PENDING = 'CUSTOMER_ID_PENDING',
	PRICING_PENDING = 'PRICING_PENDING',
	DIRECT_CHECKOUT_PENDING = 'DIRECT_CHECKOUT_PENDING',
	PAYMENT_PENDING = 'PAYMENT_PENDING',
	PARTIAL_PAYMENT_PENDING = 'PARTIAL_PAYMENT_PENDING',
	COMPLETED = 'COMPLETED',
}

export enum BopState {
	CANCELLED = 'CANCELLED',
	PENDING = 'PENDING',
	UPDATED_ORDER_PENDING = 'UPDATED_ORDER_PENDING',
	COMPLETED = 'COMPLETED',
}

export interface Cart {
	items: CartItem[];
	orderId: string | null;
}

export interface CartItem {
	code: string;
	amount: number;
	name: string | null;
	productId: string | null;
	codeBeforeAppliedOverride: string | null;
}

export enum RefundReason {
	ERROR_I_DID_NOT_BUY_THAT = 'Error / I did not buy that',
	QUALITY = 'Quality',
	OTHER = 'Other',
}

export interface RefundRemainingItem {
	upc: string;
	itemCount: number;
}

export interface RefundItem {
	upc: string;
	reason: RefundReason;
}

export interface RefundRequest {
	customerId: number;
	receiptId: string;
	time: Date;
	items: Array<RefundRemainingItem>;
	refundItems: Array<RefundItem>;
}
